<template>
  <customer-in-charge-list-tab>
    <template v-slot:content>
      <div>
        <div class="d-flex justify-space-between mb-2">
          <!-------- LEFT ITEMS ---------->
          <div class="d-flex justify-start pr-4">
            <!--  -->
            <span class="text-12px mt-1 color-title mr-2">買増評価</span>
            <v-select
              :items="itemsData.enumPurchaseRateList"
              multiple
              item-text="name"
              item-value="id"
              v-model="dataFurtherPurchaseRateSelected"
              dense
              outlined
              hide-details
              style="max-width: 270px;"
              class="mr-4 v-select-custom"
            ></v-select>
            <span class="text-12px mt-1 color-title">{{
              $t('myPage.CustomerInCharge.salesRepresentative')
            }}</span>
            <v-select
              :items="itemsData.picList"
              item-text="name"
              item-value="id"
              v-model="dataPicSelected"
              dense
              outlined
              hide-details
              style="width: 72px"
              class="ml-2 mr-4 v-select-custom"
            ></v-select>
            <span class="text-12px mt-1 color-title">最新広告施策</span>
            <my-page-client-list-campaign-list v-model="dataLastCampaignApplyResponseSelected" />
          </div>
          <!-------- RIGHT ITEMS ---------->
          <div class="d-flex justify-end mt-2">
            <span class="text-10px mt-1 color-title">担当顧客総計</span>
            <span class="text-14px" style="color: #0b6786">{{
              totalCount
            }}</span>
            <span class="text-10px mt-1" style="color: #0b6786">{{
              $t('commons.item')
            }}</span>
          </div>
        </div>
        <v-card>
          <customer-in-charge-table
            ref="table"
            :total="totalCount"
            :filter="filter"
            :items="searchClientList"
            :funReset="loadList"
            additional
          />
        </v-card>
      </div>
    </template>
  </customer-in-charge-list-tab>
</template>

<script>
import CustomerInChargeListTab from './CustomerInChargeListTab.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import MyPageClientListCampaignList from './MyPageClientListCampaignList.vue';
import CustomerInChargeTable from './CustomerInChargeTable.vue';

export default {
  components: { 
    CustomerInChargeListTab,
    MyPageClientListCampaignList,
    CustomerInChargeTable
  },
  name: 'MypageListTab',
  data() {
    return {
      filter: {
        purchaseRates: [],
        picIds: null,
        lastCampaignId: null
      },
      orderBy: [],
      totalCount: 0,
      ready: false,
      itemsData: {
        enumPurchaseRateList: [],
        picList: [],
        lastCampaignApplyResponseList: []
      }
    };
  },
  computed: {
    email() {
      return !this.$store.getters.user ? false : this.$store.getters.user.email;
    },
    ...mapGetters([
      'searchClientList',
      'renewPurchaseRate',
      'pic',
      'lastCampaignApplyResponse',
      'furtherPurchaseRateSelected',
      'picSelected',
      'lastCampaignApplyResponseSelected',
    ]),
    dataFurtherPurchaseRateSelected: {
      get() {
        return this.furtherPurchaseRateSelected;
      },
      set(value) {
        this.setFurtherPurchaseRateSelected(value);
      },
    },
    dataPicSelected: {
      get() {
        return this.picSelected;
      },
      set(value) {
        this.setPicSelected(value);
      },
    },
    dataLastCampaignApplyResponseSelected: {
      get() {
        return this.lastCampaignApplyResponseSelected;
      },
      set(value) {
        this.setLastCampaignApplyResponseSelected(value);
      },
    },
  },
  watch: {
    dataFurtherPurchaseRateSelected () {
      this.filter.purchaseRates = this.dataFurtherPurchaseRateSelected
      this.$refs.table.reset()
    },
    dataPicSelected () {
      this.filter.picIds = this.dataPicSelected
      this.$refs.table.reset()
    },
    dataLastCampaignApplyResponseSelected () {
      this.filter.lastCampaignId = this.dataLastCampaignApplyResponseSelected
      this.$refs.table.reset()
    },
  },
  async mounted() {
    this.actionStaffList().then(pic => {
      this.itemsData.picList.push({ id: null, name: '指定なし' });
      pic.forEach(element => {
        this.itemsData.picList.push({id: element.id, name: element.name })
      })
    })
    this.actionEnumPurchaseRateList().then((enumPurchaseRateList) => {
      this.itemsData.enumPurchaseRateList = enumPurchaseRateList;
    })
    this.setFurtherPurchaseRateSelected([21, 31, 41]);
    this.ready = true;
    this.$refs.table.reset()
  },
  methods: {
    ...mapActions(['getSearchClientList', 'actionEnumPurchaseRateList', 'getEnumAdditionalPurchase', 'actionStaffList']),
    ...mapMutations([
      'setFurtherPurchaseRateSelected',
      'setPicSelected',
      'setLastCampaignApplyResponseSelected',
    ]),

    async loadList(variables) {
      if(variables){ 
        if (!this.ready) return;
        const {total} = await this.getSearchClientList({
          take: variables.pagination.take,
          skip: variables.pagination.skip,
          orderBy: variables.orderBy
        });
        this.totalCount = total;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.tab-custom {
  &__tab {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  &__total {
    width: 40%;
    text-align: right;
    align-self: flex-end;
    color: var(--text_active_000000);
  }
}
.info-detail-title {
  color: var(--text_active_000000);
}
@media (max-width: 600px) {
  .v-card__title {
    flex-direction: column;
    align-items: flex-end;
  }
  .intro-info div {
    display: block;
    label {
      width: auto;
      text-align: left;
    }
  }
}
.color-title {
  background: unset !important;
  color: #000000;
  word-break: keep-all;
}
.text-14px {
  font-size: 14px;
}
.table-custom {
  ::v-deep {
    .v-data-table__wrapper {
      table > tbody > tr > td {
        &:nth-child(7) {
          max-width: 330px;
        }
        &:nth-child(8) {
          max-width: 330px;
        }
        &:nth-child(9) {
          max-width: 330px;
        }
      }
    }
  }
}
</style>